import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  SimpleGrid,
  UnorderedList,
  ListItem,
  ListIcon,
} from '@chakra-ui/react'

import {
  Layout,
  Image,
  Alert,
  SEO,
  Wrapper,
  Headline,
  ContactForm,
  ImageGallery,
} from '../components'

const DatenschutzPage = () => (
  <Layout>
    <SEO
      title="Datenschutzerklärung"
      description="Datenschutzerklärung"
      lang="de"
    />
    <Box
      as="h1"
      fontWeight="extrabold"
      fontSize="3xl"
      mb="10"
      lineHeight="none"
    >
      Datenschutzerklärung
    </Box>
    <Box>
      <Wrapper>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung. Die Nutzung unserer Webseite ist in der Regel ohne
        Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten
        personenbezogene Daten (beispielsweise Name, Anschrift oder
        E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf
        freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
        Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass
        die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
        Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor
        dem Zugriff durch Dritte ist nicht möglich.
      </Wrapper>
      <Headline as="h2">Server-Log-Files</Headline>
      <Wrapper>
        Der Provider der Seiten erhebt und speichert automatisch Informationen
        in so genannten Server-Log Files, die Ihr Browser automatisch an uns
        übermittelt. Dies sind:
        <ul>
          <li>Browsertyp und Browserversion verwendetes</li>
          <li>Betriebssystem</li>
          <li>Referrer URL</li>
          <li>Hostname des zugreifenden Rechners Uhrzeit der Serveranfrage</li>
        </ul>
        Diese Daten sind nicht bestimmten Personen zuordenbar. Eine
        Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
        vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen,
        wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt
        werden.
      </Wrapper>
      <Headline as="h2">Kontaktformular</Headline>

      <Wrapper>
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
        Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
        Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
        Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne
        Ihre Einwilligung weiter.
      </Wrapper>
      <Headline as="h2">SSL-Verschlüsselung</Headline>
      <Wrapper>
        Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der
        Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die
        Sie an uns als Seitenbetreiber senden, eine SSLVerschlüsselung. Eine
        verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
        Browsers von 'http://' auf 'https://' wechselt und an dem Schloss-Symbol
        in Ihrer Browserzeile. Wenn die SSL Verschlüsselung aktiviert ist,
        können die Daten, die Sie an uns übermitteln, nicht von Dritten
        mitgelesen werden.
      </Wrapper>
    </Box>
  </Layout>
)

export default DatenschutzPage
